import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { useUser } from "reactfire";
import { useNavigate } from "react-router-dom";

import doddles from "../../../assets/doddles.png";

export const AppContainer = ({
  children,
  isLoading = false,
  verifyAuth = false,
  isLogin = false,
}) => {
  const Progress = styled(CircularProgress)(() => ({
    ".MuiCircularProgress-svg": {
      color: "white",
    },
  }));

  const navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("session"));

    if (verifyAuth) {
      if (!user) {
        navigate("/login");
        return;
      }
    }
    if (isLogin && user) {
      navigate("/home");
    }
  }, []);

  return (
    <div className='flex justify-center items-center w-full h-[100%] max-w-[512px] bg-gray-800'>
      {isLoading && (
        <div className='loading'>
          <Progress color='secondary' />
        </div>
      )}
      <div
        className='absolute w-full h-[100vh] max-w-[512px] z-0 repeat opacity-[0.13]'
        style={{ backgroundImage: `url(${doddles})` }}></div>
      <div className='h-[90vh] flex justify-center h-full w-full z-10'>
        {children}
      </div>
    </div>
  );
};
