import "./styles.scss";
import "react-swipe-to-delete-component/dist/swipe-to-delete.css";
import { useCallback, useState, useEffect } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import SwipeToDelete from "react-swipe-to-delete-component";
import {
  collection,
  query,
  addDoc,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { AppContainer, Match, Button, PoolResults } from "../../components";
import { useNavigate } from "react-router-dom";
import { env } from "../../../env";
import { createUniqueId } from "../../../utils";

export const CreatePool = () => {
  const firestore = useFirestore();
  const poolCollection = collection(firestore, "pool");
  const queryPool = query(
    poolCollection,
    where("id", "==", env.poolId),
    where("valid", "==", true)
  );

  const [matches, setMaches] = useState([]);
  const [localTeam, setLocalTeam] = useState("");
  const [visitTeam, setVisitTeam] = useState("");
  const { data, status } = useFirestoreCollectionData(queryPool);
  const [matchesWithResults, setMachesWithResults] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    if (data && data.length > 0) {
      setMachesWithResults(data[0].matches);
    }
  }, [data]);

  const setLocal = (event) => {
    setLocalTeam(event.target.value);
  };
  const setVisit = (event) => {
    setVisitTeam(event.target.value);
  };

  const createMatch = () => {
    if (localTeam.length > 1 && visitTeam.length > 1) {
      const match = {
        localTeam,
        visitTeam,
        id: createUniqueId(),
        result: null,
      };
      matches.push(match);
      setLocalTeam("");
      setVisitTeam("");
    }
  };

  const putPool = () => {
    if (matches.length > 5) {
      addDoc(poolCollection, {
        matches,
        valid: true,
        id: env.poolId,
      });
    }
  };

  const createPool = useCallback(() => {
    return matches.map((match, index) => (
      <SwipeToDelete
        key={match.id}
        classNameTag='preview-match-swipe'
        background={null}
        onDelete={() => onDeleteMatch(match.id)}>
        <div className='preview-match-container'>
          <div className='number-of-container'>{index + 1}</div>
          <div className='team-container text'>{match.localTeam}</div>
          <div className='vs-container'>VS</div>
          <div className='team-container text'>{match.visitTeam}</div>
        </div>
      </SwipeToDelete>
    ));
  }, [matches]);

  const poolResultUpdate = (dataResult) => {
    let newMatches = matchesWithResults;
    newMatches[dataResult.index].result = dataResult.result;
    setMachesWithResults(newMatches);
  };

  const createPoolResults = () =>
    data[0]?.matches.map((match, index) => (
      <PoolResults
        key={index}
        match={match}
        index={index}
        onUpdate={poolResultUpdate}
      />
    ));

  const updatePool = () => {
    const ref = doc(firestore, "pool", data[0].NO_ID_FIELD);
    updateDoc(ref, { matches: matchesWithResults }).then((_) => {
      navigation("/home");
    });
  };

  const onDeleteMatch = (matchId) => {
    setMaches(matches.filter((matches) => matches.id !== matchId));
  };

  return (
    <AppContainer verifyAuth={true} isLoading={status === "loading"}>
      <div className='flex flex-col w-[90%] justify-center z-20'>
        {!data?.length > 0 && (
          <div className='add-match-button-container'>
            <Match
              setLocal={setLocal}
              setVisit={setVisit}
              visit={visitTeam}
              local={localTeam}
            />
            <div className='button'>
              <Button
                label='Agregar'
                onClick={createMatch}
                disabled={localTeam.length < 4 || visitTeam.length < 4}
              />
            </div>
          </div>
        )}
        {!data?.length > 0 && (
          <div className='matchs-container'>{createPool()}</div>
        )}
        {!data?.length > 0 && (
          <div className='send-pool-button-container'>
            {matches.length > 5 && (
              <Button label='Guardar' onClick={putPool} disabled={false} />
            )}
          </div>
        )}
        {data?.length > 0 && (
          <div className='h-[80%] mt-10 overflow-auto'>
            {createPoolResults()}
          </div>
        )}
        {data?.length > 0 && (
          <div className='flex flex-grow mb-5 justify-center items-center'>
            {
              <Button
                label='Actualizar'
                onClick={updatePool}
                disabled={false}
              />
            }
          </div>
        )}
      </div>
    </AppContainer>
  );
};
