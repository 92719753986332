import "./styles.scss";

import { Input } from "../input";

export const Match = ({ setVisit, setLocal, visit, local }) => {
  return (
    <div className='match-container'>
      <Input value={local} onChange={setLocal} />
      <span className='vs'>VS</span>
      <Input value={visit} onChange={setVisit} />
    </div>
  );
};
