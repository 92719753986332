import { useState } from "react";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { AppContainer, Button, Input } from "../../components";
import logo from "../../../assets/logo.png";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const auth = getAuth();
  const navigate = useNavigate();

  // * method to save email
  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  // * method to save password
  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  // * method to do login
  const doLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        localStorage.setItem("session", JSON.stringify(user));
        navigate("/home");
      })
      .catch(() => {
        window.alert("Usuario y/o contraseña incorrecta");
      });
  };

  return (
    <AppContainer isLoading={isLoading} isLogin>
      <div className='flex flex-col'>
        <div className='flex justify-center mt-5 h-[40%]'>
          <img src={logo} alt='Logo' className='h-[90%]' />
        </div>
        <div className='flex flex-1 flex-col items-center mt-6'>
          <div className='flex flex-col w-[20rem]'>
            <Input
              value={email}
              onChange={onChangeEmail}
              label='Email'
              autoComplete='off'
            />
            <Input
              value={password}
              onChange={onChangePassword}
              label='Password'
              password
            />
          </div>
          <div className='flex items-end h-full mb-10 mt-5'>
            <Button
              label='Login'
              onClick={doLogin}
              disabled={email.length < 10 || password.length < 6}
            />
          </div>
        </div>
      </div>
    </AppContainer>
  );
};
