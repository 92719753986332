import "./styles.scss";

import { AppContainer } from "../../components";

import { collection, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { useNavigate } from "react-router-dom";
import { env } from "../../../env";

export const Results = () => {
  const firestore = useFirestore();
  const poolCollection = collection(firestore, "pool");
  const userPoolCollection = collection(firestore, "usersPools");
  const usersCollection = collection(firestore, "users");
  const permissionsCollection = collection(firestore, "permissions");
  const navigation = useNavigate();

  const queryPool = query(
    poolCollection,
    where("id", "==", env.poolId),
    where("valid", "==", true)
  );
  const queryUsersPool = query(
    userPoolCollection,
    where("poolId", "==", env.poolId)
  );

  const queryUsers = query(usersCollection, where("active", "==", true));

  const { data: pool } = useFirestoreCollectionData(queryPool);
  const { data: usersPools } = useFirestoreCollectionData(queryUsersPool);
  const { data: users } = useFirestoreCollectionData(queryUsers);
  const { data: permissions } = useFirestoreCollectionData(
    permissionsCollection
  );

  const getList = () => {
    if (users && users.length > 0) {
      const usersObject = users.map((user) => ({
        name: user.name,
        result: getResult(user.uid),
        class: getClass(user.uid),
        pool: usersPools
          ? usersPools.filter((pool) => pool.user === user.uid)
          : null,
      }));

      const sorterArray = usersObject.sort((a, b) => b.result - a.result);

      return sorterArray.map((user, index) => (
        <div
          key={index}
          className='user-result-container'
          onClick={() => navigateToDetail(user.pool[0]?.matches, user.name)}>
          <span className={`${user.class} name`}>{user.name}:</span>
          <span className={`${user.class} result`}>{user.result}</span>
        </div>
      ));
    }
  };

  const getClass = (user) => {
    if (pool) {
      const userPool = usersPools
        ? usersPools.filter((pool) => pool.user === user)
        : [];
      const userMatches = userPool[0]?.matches;
      if (!userMatches) {
        return "no-pool";
      }
    }
  };

  const getResult = (user) => {
    let result = 0;
    if (pool && pool.length > 0) {
      const matchesResults = pool[0].matches;
      const userPool = usersPools
        ? usersPools.filter((pool) => pool.user === user)
        : [];
      const userMatches = userPool ? userPool[0]?.matches : [];
      if (userMatches) {
        userMatches.forEach((userMatch) => {
          const match = matchesResults.filter(
            (poolMatch) =>
              poolMatch.localTeam === userMatch.localTeam &&
              poolMatch.visitTeam === userMatch.visitTeam
          );

          if (match[0].result === userMatch.result) {
            result = result + 1;
          }
        });
      }
    }

    return result;
  };

  const navigateToDetail = (userPool, userName) => {
    if (userPool && permissions[0].seePools) {
      localStorage.setItem("matches", JSON.stringify(pool[0].matches));
      localStorage.setItem("userMatches", JSON.stringify(userPool));
      localStorage.setItem("userName", userName);
      navigation("/pool-detail");
    }
  };

  return (
    <AppContainer>
      <div className='w-[90%] z-20'>
        <p className='my-5 text-center text-3xl text-white font-bold'>
          Resultados
        </p>
        {pool && (
          <div className='border-t-[1px] h-[85%] overflow-auto'>
            {getList()}
          </div>
        )}
      </div>
    </AppContainer>
  );
};
