export const createUniqueId = (size = 30) => {
  let id = "";
  for (let i = 0; i < size; i++) {
    const character = Math.round(Math.random() * (60 - 0));
    id = `${id}${characters[character]}`;
  }

  return id;
};

const characters = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

// "0",
// "1",
// "2",
// "3",
// "4",
// "5",
// "6",
// "7",
// "8",
// "9",
// "a",
// "b",
// "c",
// "d",
// "e",
// "f",
// "g",
// "h",
// "i",
// "j",
// "k",
// "l",
// "m",
// "n",
// "ñ",
// "o",
// "p",
// "q",
// "r",
// "s",
// "t",
// "u",
// "v",
// "w",
// "x",
// "y",
// "z",
// "A",
// "B",
// "C",
// "D",
// "E",
// "F",
// "G",
// "H",
// "I",
// "J",
// "K",
// "L",
// "M",
// "N",
// "Ñ",
// "O",
// "P",
// "Q",
// "R",
// "S",
// "T",
// "U",
// "V",
// "W",
// "X",
// "Y",
// "Z", "
// ",
