import * as React from "react";
import { Button as MaterialButton } from "@mui/material";
import { styled } from "@mui/system";

export const Button = ({
  label = "button",
  disabled = false,
  onClick,
  ...props
}) => {
  const ColorButton = styled(MaterialButton)(({ theme }) => ({
    color: "black",
    backgroundColor: "#f6f6f6",
    fontWeight: "bold",
    height: "45px",
    width: "256px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#b8b8b8",
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(255,255,255,0.5)",
      fontWeight: "normal",
    },
  }));
  return (
    <ColorButton
      variant='contained'
      size='large'
      onClick={onClick}
      disabled={disabled}>
      {label}
    </ColorButton>
  );
};
