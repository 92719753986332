import "./styles.scss";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import clsx from "clsx";

const initStyle = {
  color: "white",
  "&.Mui-checked": {
    color: "white",
  },
};

const winnerStyle = {
  color: "rgb(63,173, 40)",
  "&.Mui-checked": {
    color: "rgb(63,173, 40)",
  },
};

const looserStyle = {
  color: "red",
  "&.Mui-checked": {
    color: "red",
  },
};

export const PoolResults = ({
  match,
  index,
  onUpdate,
  clear = false,
  disable = false,
  result,
}) => {
  const [localTeam, setLocalTeam] = useState(false);
  const [visitTeam, setVisitTeam] = useState(false);
  const [tie, setTie] = useState(false);
  const [style, setStyle] = useState(initStyle);

  useEffect(() => {
    if (clear) {
      setLocalTeam(false);
      setVisitTeam(false);
      setTie(false);
      return;
    }

    if (match.result === "L") {
      return setLocalTeam(true);
    }
    if (match.result === "V") {
      return setVisitTeam(true);
    }
    if (match.result === "T") {
      return setTie(true);
    }
  }, [match, clear]);

  useEffect(() => {
    if (result === null || result === undefined) setStyle(initStyle);
    else if (result) setStyle(winnerStyle);
    else setStyle(looserStyle);
  }, [result]);

  const selectLocal = () => {
    setLocalTeam(true);
    setVisitTeam(false);
    setTie(false);
    onUpdate({ index, result: "L" });
  };
  const selectVisit = () => {
    setLocalTeam(false);
    setVisitTeam(true);
    setTie(false);
    onUpdate({ index, result: "V" });
  };
  const selectTie = () => {
    setLocalTeam(false);
    setVisitTeam(false);
    setTie(true);
    onUpdate({ index, result: "T" });
  };

  return (
    <div className='flex w-full justify-around justify-center items-center'>
      <Checkbox
        disabled={disable}
        sx={style}
        checked={localTeam}
        onChange={selectLocal}
      />
      <span
        className={clsx("text-base flex-1 text-center", {
          "line-through": result === false,
        })}>
        {match.localTeam}
      </span>
      <Checkbox
        disabled={disable}
        checked={tie}
        sx={style}
        onChange={selectTie}
      />
      <span
        className={clsx("text-base flex-1 text-center", {
          "line-through": result === false,
        })}>
        {match.visitTeam}
      </span>
      <Checkbox
        disabled={disable}
        checked={visitTeam}
        sx={style}
        onChange={selectVisit}
      />
    </div>
  );
};
