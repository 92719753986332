import "./styles.scss";
import { useState } from "react";
import { signOut, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirebaseApp,
} from "reactfire";

import { AppContainer } from "../../components";
import { USER_SUPER_ADMIN } from "../../../constants";
import pool from "../../../assets/pool.png";
import results from "../../../assets/results.png";
import logoutimg from "../../../assets/logout.png";
import createPool from "../../../assets/add.png";
import activePermissions from "../../../assets/active-permissions.png";
import desactivePermissions from "../../../assets/desactive-permissions.png";
import users from "../../../assets/admin-users.png";
import resetimg from "../../../assets/reload.png";
import uploadImg from "../../../assets/update.png";
import { env } from "../../../env";

import errorImage from "../../../assets/out_of_time.png";
import clsx from "clsx";

export const Home = () => {
  const session = JSON.parse(localStorage.getItem("session"));

  const app = useFirebaseApp();
  const firestore = useFirestore();
  const navigation = useNavigate();
  const auth = getAuth(app);

  // * component state
  const [resetCounter, setResetCounter] = useState(0);

  // * firestore collections
  const usersCollection = collection(firestore, "users");
  const userPoolCollection = collection(firestore, "usersPools");
  const poolCollection = collection(firestore, "pool");
  const permissionsCollection = collection(firestore, "permissions");

  // * firestore queries
  const queryPool = query(
    poolCollection,
    where("id", "==", env.poolId),
    where("valid", "==", true)
  );
  const queryUser = query(
    usersCollection,
    where("uid", "==", session ? session.uid : "")
  );
  const queryUsersPool = query(
    userPoolCollection,
    where("poolId", "==", env.poolId)
  );

  // * firestore data
  const { data: mainPool } = useFirestoreCollectionData(queryPool);
  const { data: permissions } = useFirestoreCollectionData(
    permissionsCollection
  );
  const { data: user } = useFirestoreCollectionData(queryUser);
  const { data: usersPools } = useFirestoreCollectionData(queryUsersPool);

  //* method to logout
  const logout = () => {
    signOut(auth);
    localStorage.clear("session");
    navigation("/login");
  };

  //* method to reset pools
  const reset = () => {
    if (resetCounter === 2) {
      usersPools.forEach((pool) => {
        const ref = doc(firestore, "usersPools", pool.NO_ID_FIELD);
        deleteDoc(ref);
      });
      mainPool.forEach((pool) => {
        const ref = doc(firestore, "pool", pool.NO_ID_FIELD);
        deleteDoc(ref);
      });

      const ref = doc(firestore, "permissions", "permissions");
      updateDoc(ref, {
        seePools: false,
        isInTime: true,
      });

      window.alert("Quiniela reiniciada");
      return setResetCounter(0);
    }
    setResetCounter(resetCounter + 1);
  };

  //* method to update permission to see user's pools
  const updatePermission = () => {
    const ref = doc(firestore, "permissions", "permissions");
    updateDoc(ref, {
      seePools: !permissions[0].seePools,
      isInTime: !permissions[0].isInTime,
    }).then((_) => {
      window.alert("Permisos actualizados");
    });
  };

  //* validate fetch data
  const dataIsValid = () => {
    const validMainPool = mainPool !== null && mainPool !== undefined;
    const validPermissions = permissions && permissions.length > 0;
    const validUser = user && user.length > 0;
    const validUsersPools = usersPools !== null && usersPools !== undefined;

    return validMainPool && validPermissions && validUser && validUsersPools;
  };

  //* validate if user is admin
  const isUserAdmin = () => dataIsValid() && user[0].admin;

  //* validate if user is super admin
  const isUserSuperAdmin = () =>
    isUserAdmin() && user[0].uid === USER_SUPER_ADMIN;

  //* validate if exist active pool
  const isMainPoolActive = () => mainPool && mainPool.length > 0;

  return (
    <AppContainer verifyAuth={true}>
      {dataIsValid && user && (
        <div className='flex flex-col w-[95%] mt-10'>
          <div className='flex flex-col mt-5'>
            <span className='text-center text-white'>
              {user ? user[0]?.name : ""}
            </span>
          </div>
          <div
            className={clsx("flex-col h-full w-full", {
              flex: user && user[0]?.active,
              hidden: user && !user[0]?.active,
            })}>
            <div className='flex flex-1 justify-center'>
              <div className='gap-[38px] grid grid-cols-3 mt-[5rem]'>
                <div
                  className='funtionality'
                  onClick={() => navigation("/view-pool")}>
                  <div className='img-container'>
                    <img className='img' src={pool} alt='quiniela' />
                  </div>
                  <div className='label-container'>
                    <label>Quiniela</label>
                  </div>
                </div>
                <div
                  className='funtionality'
                  onClick={() => navigation("/results")}>
                  <div className='img-container'>
                    <img className='img' src={results} alt='quiniela' />
                  </div>
                  <div className='label-container'>
                    <label>Resultados</label>
                  </div>
                </div>
                <div className='funtionality' onClick={logout}>
                  <div className='img-container'>
                    <img className='img' src={logoutimg} alt='logout' />
                  </div>
                  <div className='label-container'>
                    <label>Logout</label>
                  </div>
                </div>
              </div>
            </div>
            {isUserAdmin() && (
              <span className='text-3xl text-white text-center mt-10 mb-5'>
                Administración
              </span>
            )}
            <div className='flex justify-center mb-[5rem]'>
              {isUserAdmin() && (
                <div className='gap-[38px] grid grid-cols-3 mt-5'>
                  <div
                    className='funtionality'
                    onClick={() => navigation("/create-pool")}>
                    <div className='img-container'>
                      <img
                        className='img'
                        src={isMainPoolActive() ? uploadImg : createPool}
                        alt='quiniela'
                      />
                    </div>
                    <div className='label-container'>
                      <label>
                        {isMainPoolActive() ? "Actualizar" : "Crear"}
                      </label>
                    </div>
                  </div>
                  <div
                    className='funtionality'
                    onClick={() => navigation("/admin-users")}>
                    <div className='img-container'>
                      <img className='img' src={users} alt='admin users' />
                    </div>
                    <div className='label-container'>
                      <label>Usuarios</label>
                    </div>
                  </div>

                  <div
                    className={clsx("funtionality", {
                      visible: isUserSuperAdmin(),
                      invisible: !isUserSuperAdmin(),
                    })}
                    onClick={updatePermission}>
                    <div className='img-container'>
                      <img
                        className='img'
                        src={
                          permissions && permissions[0].seePools
                            ? activePermissions
                            : desactivePermissions
                        }
                        alt='quiniela'
                      />
                    </div>
                    <div className='label-container'>
                      <label>Permisos</label>
                    </div>
                  </div>

                  <div
                    className={clsx("funtionality", {
                      visible: isUserSuperAdmin(),
                      invisible: !isUserSuperAdmin(),
                    })}
                    onClick={reset}>
                    <div className='img-container'>
                      <img className='img' src={resetimg} alt='quiniela' />
                    </div>
                    <div className='label-container'>
                      <label>Reiniciar</label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={clsx("flex-col mt-[8rem] items-center h-full w-full", {
              flex: user && !user[0]?.active,
              hidden: user && user[0]?.active,
            })}>
            <img className='w-[128px] h-[128px]' src={errorImage} alt='Error' />
            <span className='text-center text-white text-xl'>
              Cuenta inactiva, contacta a tu administrador
            </span>
          </div>
        </div>
      )}
    </AppContainer>
  );
};
