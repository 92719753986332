import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& input": {
    color: "white",
  },
  width: "100%",
  marginTop: "32px",

  "& label.MuiFormLabel-colorPrimary": {
    color: "#b8b8b8",
  },
  "& label.Mui-focused": {
    color: "white",
  },
  "& label.MuiFormLabel-filled": {
    color: "#b8b8b8",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#b8b8b8",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },

    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});

export const Input = ({ value, password = false, ...props }) => {
  return (
    <CssTextField
      value={value}
      type={password ? "password" : "text"}
      {...props}
    />
  );
};

/*


 <CssTextField
        {...props}
        id='outlined-adornment-password'
        type={showPassword ? "text" : "password"}
        value={value}
        endAdornment={
          <InputAdornment position='end'>
            {showPassword ? (
              <IconButton
                aria-label='toggle password visibility'
                onClick={changeShowPassword}
                edge='end'></IconButton>
            ) : null}
          </InputAdornment>
        }
        label='Password'
      />

*/

/*


 <FormControl sx={{ m: 1, width: "25ch" }} variant='filled'>
      <InputLabel htmlFor='filled-adornment-password'>Password</InputLabel>
      <FilledInput
        id='filled-adornment-password'
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={changeShowPassword}
              edge='end'>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>

*/
