import "./styles.scss";
import { useState, useEffect } from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { collection, query, addDoc, where } from "firebase/firestore";

import { AppContainer, Button, PoolResults } from "../../components";
import { POOL_ID } from "../../../constants";
import outOfTimeImg from "../../../assets/out_of_time.png";

export const ViewPool = () => {
  const session = JSON.parse(localStorage.getItem("session"));

  const firestore = useFirestore();

  const [matchesWithResults, setMachesWithResults] = useState([]);
  const [isPoolValid, setIsPoolValid] = useState(false);
  const [mainPool, setmainPool] = useState({});
  const [userPool, setUserPool] = useState({});
  const [permissions, setPermissions] = useState({});

  const poolCollection = collection(firestore, "pool");
  const userPoolCollection = collection(firestore, "usersPools");
  const permissionsCollection = collection(firestore, "permissions");

  // * firestore queries
  const queryPool = query(
    poolCollection,
    where("id", "==", POOL_ID),
    where("valid", "==", true)
  );
  const queryUserPool = query(
    userPoolCollection,
    where("poolId", "==", POOL_ID),
    where("user", "==", session.uid)
  );

  // * firestore fetch data
  const { data: mainPoolFetchData } = useFirestoreCollectionData(queryPool);
  const { data: userPoolFetchData } = useFirestoreCollectionData(queryUserPool);
  const { data: permissionsFetchData } = useFirestoreCollectionData(
    permissionsCollection
  );

  useEffect(() => {
    if (mainPool.matches) {
      const matches = mainPool.matches.map((match) => {
        return {
          localTeam: match.localTeam,
          visitTeam: match.visitTeam,
          result: null,
        };
      });
      setMachesWithResults(matches);
    }
  }, [mainPool]);

  useEffect(() => {
    if (mainPoolFetchData && mainPoolFetchData.length > 0) {
      setmainPool(mainPoolFetchData[0]);
    }
  }, [mainPoolFetchData]);

  useEffect(() => {
    if (userPoolFetchData && userPoolFetchData.length > 0) {
      setUserPool(userPoolFetchData[0]);
    }
  }, [userPoolFetchData]);

  useEffect(() => {
    if (permissionsFetchData && permissionsFetchData.length > 0) {
      setPermissions(permissionsFetchData[0]);
    }
  }, [permissionsFetchData]);

  // * put result in every match
  const poolResultUpdate = (dataResult) => {
    let newMatches = matchesWithResults;
    newMatches[dataResult.index].result = dataResult.result;
    validPool(newMatches);
    setMachesWithResults(newMatches);
  };

  // * valid if user set all results
  const validPool = (matches) => {
    let valid = true;
    matches.forEach((match) => {
      if (!match.result) valid = false;
    });
    setIsPoolValid(valid);
  };

  // * user doesn't have results
  const createPool = () => {
    if (!permissions.isInTime) {
      return (
        <div className='empty-pool-container'>
          <img className='img' src={outOfTimeImg} alt='out of time' />
          <span className='text-xl'>No llenaste tu quiniela a tiempo</span>
        </div>
      );
    }

    if (mainPool.matches) {
      return mainPool.matches.map((match, index) => (
        <PoolResults
          key={index}
          match={match}
          index={index}
          clear
          onUpdate={poolResultUpdate}
        />
      ));
    }
  };

  // * user has results
  const createBlockPool = () => {
    if (userPool.matches) {
      return userPool.matches.map((match, index) => (
        <PoolResults key={index} match={match} index={index} disable />
      ));
    }
  };

  // * method to set results
  const updateUserPool = () => {
    setIsPoolValid(false);
    addDoc(userPoolCollection, {
      matches: matchesWithResults,
      poolId: mainPool.id,
      user: session.uid,
    });
  };

  return (
    <AppContainer>
      {Object.keys(mainPool).length === 0 ? (
        <div className='empty-pool-container'>
          <img className='img' src={outOfTimeImg} alt='quiniela' />
          <span className='text-white text-center text-2xl'>
            No tienes quiniela por llenar
          </span>
        </div>
      ) : (
        <div className='view-pool-container z-20'>
          <div className='mt-5'>
            <span className='text-white'>Mi quiniela</span>
          </div>
          <div className='pool-container'>
            {userPool.matches ? createBlockPool() : createPool()}
          </div>
          {!userPool.matches && (
            <div className='send-button-container'>
              {isPoolValid && (
                <Button
                  label='Enviar'
                  onClick={updateUserPool}
                  disabled={false}
                />
              )}
            </div>
          )}
        </div>
      )}
    </AppContainer>
  );
};
