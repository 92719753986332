import "./styles.scss";

import { AppContainer, PoolResults } from "../../components";

export const PoolDetail = () => {
  const poolMatches = JSON.parse(localStorage.getItem("matches"));
  const userMatches = JSON.parse(localStorage.getItem("userMatches"));
  const userName = localStorage.getItem("userName");

  const createPool = () => {
    const matches = verifyResult();

    return matches.map((match, index) => {
      return (
        <div
          className={`${
            match.success === null ? "" : match.success ? "winner" : "loser"
          } match`}
          key={index}>
          <PoolResults match={match} disable result={match.success} />
        </div>
      );
    });
  };

  const getTotal = () => {
    const matches = verifyResult();
    const total = matches.filter((match) => match.success === true);
    return `Total: ${total.length}`;
  };

  const verifyResult = () => {
    let matches = [];

    userMatches.forEach((userMatch) => {
      const comparative = poolMatches.filter(
        (poolMatch) =>
          poolMatch.localTeam === userMatch.localTeam &&
          poolMatch.visitTeam === userMatch.visitTeam
      );

      let matchesResult;

      if (
        comparative[0].result === null ||
        comparative[0].result === undefined
      ) {
        matchesResult = {
          ...userMatch,
          success: null,
        };
      } else {
        const success = comparative[0].result === userMatch.result;
        matchesResult = {
          ...userMatch,
          success,
        };
      }

      matches.push(matchesResult);
    });

    return matches;
  };

  return (
    <AppContainer>
      <div className='pool-detail-container'>
        <span className='title'>{userName}</span>
        <div className='matches-container'>{createPool()}</div>
        <span className='title'>{getTotal()}</span>
      </div>
    </AppContainer>
  );
};
