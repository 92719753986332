import "./App.scss";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { useFirebaseApp, AuthProvider, FirestoreProvider } from "reactfire";
import { AppRoutes } from "./routes";

function App() {
  const app = useFirebaseApp();
  const firestoreDatabase = getFirestore(app);
  const auth = getAuth(app);
  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreDatabase}>
        <div className='app'>
          <AppRoutes />
        </div>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
