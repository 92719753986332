import { AppContainer } from "../../components";

import { collection, updateDoc, doc } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import clsx from "clsx";

import enableImg from "../../../assets/enable.png";
import disableImg from "../../../assets/disable.png";

export const AdminUsers = () => {
  const firestore = useFirestore();
  const usersCollection = collection(firestore, "users");
  const { data: users } = useFirestoreCollectionData(usersCollection);
  const updateUser = (docRef, active) => {
    const ref = doc(firestore, "users", docRef);
    updateDoc(ref, { active: !active }).then((_) => {});
  };

  const getList = () => {
    if (users && users.length > 0) {
      const usersObject = users.map((user) => ({
        name: user.name,
        active: user.active,
        docRef: user.NO_ID_FIELD,
      }));

      return usersObject.map((user, index) => (
        <div
          key={index}
          className='flex justify-between items-center px-1 border-b-[1px] mb-3'
          onClick={() => {}}>
          <span
            className={clsx("text-white text-lg", {
              "opacity-50": !user.active,
            })}>
            {user.name}
          </span>
          <img
            className='w-[18px] h-[18px]'
            src={user.active ? enableImg : disableImg}
            alt='Check'
            onClick={() => updateUser(user.docRef, user.active)}
          />
        </div>
      ));
    }
  };

  return (
    <AppContainer>
      <div className='flex flex-col justify-center items-center w-[95%] z-20'>
        <p className='text-3xl text-white font-bold text-center mt-10'>
          Administración de <br /> Usuarios
        </p>
        <div className='w-[90%] h-[85%] my-10 overflow-auto'>{getList()}</div>
      </div>
    </AppContainer>
  );
};
